import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询抽检人员记录列表
export function listSamplingRecord(query) {
  return request({
    url: '/jt808/samplingRecord/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 获取抽检人员记录详细信息
export function getSamplingRecord(id) {
  return request({
    url: '/jt808/samplingRecord/' + id,
    method: 'get',
    baseURL
  })
}
