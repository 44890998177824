<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="formTitle" @close="onClose">
    <a-card title="在岗抽查详情" class="detail" headStyle="display:none">
      <div class="container">
        <div class="row">
          <span>
            <a class="route-name" href="#"> <span @click="toFence(form)">路段名称：</span></a>
          </span>
          <a class="route-name" href="#">
            <span @click="toFence(form)">{{ form.name }}</span></a
          >
        </div>
        <div class="row">
          <span>班次时段：</span>
          <span> {{ form.startTime + '-' + form.endTime }} </span>
        </div>
        <div class="row">
          <span>应在岗人数：</span>
          <span> {{ form.mustOnDutyCount }} </span>
        </div>
        <div class="row">
          <span>实际在岗人数：</span>
          <span> {{ form.realOnDutyCount }} </span>
        </div>
        <div class="row">
          <span>抽查结果：</span>
          <span> {{ form.status ? '正常' : '异常' }} </span>
        </div>
        <div class="row">
          <span>抽查时间：</span>
          <span> {{ form.createTime }} </span>
        </div>
      </div>
    </a-card>

    <a-card title="在岗清单" class="on-duty">
      <div class="on-duty-container">
        <span class="user" v-for="(item, index) in employees" :key="index"><img :src="cleanerUrl" alt="" />{{ item.name }}</span>
      </div>
    </a-card>
    <area-create-form ref="areaCreateForm" />
  </a-drawer>
</template>

<script>
import { getSamplingRecord } from '@/api/jt808/samplingRecord'
import AreaCreateForm from '@/views/jt808/areaManager/modules/CreateForm'
export default {
  name: 'CreateForm',
  props: {},
  components: {
    AreaCreateForm
  },
  data() {
    return {
      loading: false,
      formTitle: '在岗抽查详情',
      // 表单参数
      form: {
        name: null,
        startTime: null,
        endTime: null,
        mustOnDutyCount: null,
        realOnDutyCount: null,
        status: null,
        createTime: null
      },
      open: false,
      cleanerUrl: require('@/assets/images/map/cleaner.png'),
      employees: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    toFence(record) {
      this.$refs.areaCreateForm.handleUpdate(null, record.areaId, 'read')
    },
    onClose() {
      this.open = false
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.open = true
      const id = row ? row.id : ids
      getSamplingRecord(id).then((response) => {
        this.form = response.data
        this.form.name = row.name
        this.employees = response.employees
        this.open = true
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  .row {
    display: flex;
    padding-bottom: 10px;
    span:first-child {
      min-width: 100px;
      text-align: right;
    }
  }
}
.detail {
  margin-bottom: 20px;
}
.on-duty {
  .on-duty-container {
    display: flex;
    flex-wrap: wrap;
    .user {
      display: flex;
      align-items: center;
      padding-right: 15px;
      img {
        width: 35px;
      }
    }
  }
}
</style>
