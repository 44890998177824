var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-card",
        {
          staticClass: "detail",
          attrs: { title: "在岗抽查详情", headStyle: "display:none" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("span", [
                _c("a", { staticClass: "route-name", attrs: { href: "#" } }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toFence(_vm.form)
                        },
                      },
                    },
                    [_vm._v("路段名称：")]
                  ),
                ]),
              ]),
              _c("a", { staticClass: "route-name", attrs: { href: "#" } }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toFence(_vm.form)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.form.name))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("班次时段：")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.form.startTime + "-" + _vm.form.endTime) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("应在岗人数：")]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.form.mustOnDutyCount) + " "),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("实际在岗人数：")]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.form.realOnDutyCount) + " "),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("抽查结果：")]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.form.status ? "正常" : "异常") + " "),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("抽查时间：")]),
              _c("span", [_vm._v(" " + _vm._s(_vm.form.createTime) + " ")]),
            ]),
          ]),
        ]
      ),
      _c("a-card", { staticClass: "on-duty", attrs: { title: "在岗清单" } }, [
        _c(
          "div",
          { staticClass: "on-duty-container" },
          _vm._l(_vm.employees, function (item, index) {
            return _c("span", { key: index, staticClass: "user" }, [
              _c("img", { attrs: { src: _vm.cleanerUrl, alt: "" } }),
              _vm._v(_vm._s(item.name)),
            ])
          }),
          0
        ),
      ]),
      _c("area-create-form", { ref: "areaCreateForm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }